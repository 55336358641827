import { baseURL, URL_MINOR } from "../../../config";
import { compressorImage } from "@/utils/compressor";
import SparkMd5 from "spark-md5";
export let uploadImageMixins = {
    props: {
        actionImage: {
            type: String,
            default: URL_MINOR.upload.uploadImage
        },
        actionVideo: {
            type: String,
            default: URL_MINOR.upload.uploadFile
        },
        headers: {
            type: Object,
            default: function() {
                return {
                    token: this.$store.state.token
                    // 'content-type': 'application/x-www-form-urlencoded'
                };
            }
        },
        params: {
            type: Object,
            default: function() {
                return {
                    md5: "", //	文件小写的md5	query	false
                    length: "", // 文件长度	query	false
                    extend: "" // 文件后缀,目前仅支持[jpg,jpeg,png,mp4]
                };
            }
        },
        multiple: {
            // 是否支持多选文件
            type: Boolean,
            default: true
        },
        limit: {
            // 最大允许上传个数
            type: Number,
            default: 9
        },
        onExceed: {
            type: Function,
            default: function() {
                this.$message.error(`上传图片不能超过 ${this.limit} 张!`);
            }
        },
        filesSize: {
            // 单张文件大小限制M
            type: Number,
            default: 10
        },
        filesSizeUnit: {
            type: String,
            default: "MB" //M、KB
        },
        filesType: {
            // 支持类型
            type: Array,
            default: function() {
                return ["image/jpeg", "image/png"];
            }
        },
        width: {
            // 容器尺寸
            type: String,
            default: "120px"
        },
        height: {
            type: String,
            default: undefined
        },
        showHintText: {
            // 是否灰色提示框
            type: Boolean,
            default: false
        },
        readOnly: {
            //是否只读
            type: Boolean,
            default: false
        },
        isCropper: {
            //是否裁剪
            type: Boolean,
            default: false
        },
        quality: {
            type: Number,
            default: 0.5
        },
        canvas: {
            type: Object,
            default: () => {}
        },
        aspectRatio: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            loading: false,
            /* 文件上传 */
            list: [],
            /* 文件放大查看 */
            dialogImageUrl: "",
            dialogVisible: false,
            dialogVisibleCropper: false,
            disabled: false
        };
    },
    created() {},
    methods: {
        closeLoading() {
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        /**
         * 文件上传之前
         */
        uploadBefore(file, origin) {
            const imagesTypes = this.filesType;
            const isType = imagesTypes.includes(file.type.toLowerCase());
            let isMB;
            console.log( this.filesSize/1024, this.filesSizeUnit);
            if (/\s*kb\s*/gi.test(this.filesSizeUnit.toLowerCase())) {
                console.log(file,'gsh')
                isMB = file.size / 1024 <= this.filesSize;
            } else {
                console.log(file,'hsg')
                isMB = file.size / 1024 / 1024 <= this.filesSize;
            }
            if (!isType) {
                this.$message.error(
                    "上传格式不正确！仅支持格式：" + imagesTypes.toString()
                );
                return false;
            }

            if (!isMB) {
                this.$message.error(
                    `上传文件大小不能超过 ${this.filesSize} ${this.filesSizeUnit}!`
                );
                return false;
            }
            return true;
        },
        /**
         * 文件上传后
         */
        uploadSuccess(res, file, fileList) {},
        /**
         * 文件上传错误
         */
        uploadError(file) {
            this.$message.error(
                file.name + "：" + (file.message || file.msg || "上传失败")
            );
        },
        /** 放大查看 **/
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file;
            this.dialogVisible = true;
        },
        uploadTencentCos(res, file) {
            let param = new FormData();
            param.append("q-sign-algorithm", res.signAlgorithm);
            param.append("q-ak", res.ak);
            param.append("q-signature", res.signature);
            param.append("q-key-time", res.keyTime);
            param.append("key", res.key);
            param.append("policy", res.policy);
            param.append("file", file);
            return this.axios
                .post(res.postUrl, param, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    return Promise.resolve(res);
                })
                .catch(res => {
                    console.error(res);
                });
        },
        /**  用符号上传到oss */
        upLoadToOssWithSign(URL, fun) {
            let param = new FormData();
            param.append("md5", this.params.md5);
            param.append("length", this.params.length);
            param.append("extend", this.params.extend);
            this.axios
                .post(URL, param, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    if (res.code === 0) {
                        fun(res);
                    }
                });
        },
        /**  用符号上传到oss */
        moreUpLoadToOssWithSign(URL, params, fun) {
            let param = new FormData();
            param.append("md5", this.params.md5);
            param.append("length", this.params.length);
            param.append("extend", this.params.extend);
            this.axios
                .post(URL, param, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    if (res.code === 0) {
                        fun(res);
                    }
                });
        },
        fileSparkMD5(file) {
            let chunkSize = 1024;
            return new Promise((resolve, reject) => {
                let blobSlice =
                    File.prototype.slice ||
                    File.prototype.mozSlice ||
                    File.prototype.webkitSlice;
                let chunks = Math.ceil(file.size / chunkSize);
                let currentChunk = 0;
                let spark = new SparkMd5.ArrayBuffer();
                let fileReader = new FileReader();

                fileReader.onload = function(e) {
                    spark.append(e.target.result);
                    currentChunk++;
                    if (currentChunk < chunks) {
                        loadNext();
                    } else {
                        let md5 = spark.end();
                        resolve(md5);
                    }
                };
                fileReader.onerror = function(e) {
                    reject(e);
                };

                function loadNext() {
                    let start = currentChunk * chunkSize;
                    let end = start + chunkSize;
                    if (end > file.size) {
                        end = file.size;
                    }
                    fileReader.readAsArrayBuffer(
                        blobSlice.call(file, start, end)
                    );
                }
                loadNext();
            });
        },
        async changeCropper(file) {
            this.dialogVisibleCropper = false;
            this.fileImage = "";
            if (file) {
                compressorImage({
                    file
                })
                    .then(async files => {
                        if (files) {
                            const formData = new FormData();
                            this.params.extend = files.type.split("/")[1];
                            this.params.length = files.size;
                            this.params.md5 = await this.fileSparkMD5(files);

                            formData.append("length", this.params.length);
                            formData.append("extend", this.params.extend);
                            formData.append("md5", this.params.md5);
                            formData.append("file", files);
                            this.loading = true;
                            this.axios
                                .post(this.actionImage, formData)
                                .then(res => {
                                    this.uploadSuccessMulti(res, files);
                                })
                                .catch(err => {
                                    this.closeLoading();
                                });
                        } else {
                            this.$message({
                                message: "图片上传失败",
                                type: "warning"
                            });
                        }
                    })
                    .catch(e => {
                        console.error(e);
                        this.$message({
                            message: "图片上传失败",
                            type: "warning"
                        });
                    });
            }
        },

        /**
         * 手动上传
         */
        async handleUpLoadImage(params) {
            const { file } = params;
            let flag = await this.uploadBeforeMulti(file);
            if (flag) {
                if (this.isCropper) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        if (reader.result) {
                            this.fileImage = reader.result;
                            this.dialogVisibleCropper = true;
                        }
                    };
                } else {
                    this.changeCropper(file);
                }
            }
        },
        /**
         * 文件上传后
         */
        uploadSuccessMulti(res, file) {
            if (res.code !== 0) {
                this.uploadCallBack({ type: "error", file });
                return;
            }
            const { tencentCosForm, url } = res.data;

            if (tencentCosForm) {
                this.uploadTencentCos(tencentCosForm, file)
                    .then(res => {
                        this.upLoadToOssWithSign(this.actionImage, res => {
                            this.closeLoading();
                            this.uploadCallBack({
                                type: "succuss",
                                url: res.data.url
                            });
                        });
                    })
                    .catch(e => {
                        this.closeLoading();
                        this.uploadCallBack({ type: "error", file });
                    });
            } else {
                this.closeLoading();
                this.uploadCallBack({ type: "succuss", url });
            }
        }
    },
    computed: {
        sizeStyle() {
            let width = this.width;
            if (!width) return "";
            let str = `width:${width};height:${width};`;
            if (this.height) {
                str = `width:${width};height:${this.height};`;
            }
            return str;
        }
    },
    watch: {}
};
