<template>
  <div class="cropper-wrap" v-loading="loading && imageParams">
    <div class="cropper-cont mb10">
      <cropper ref="cropper" class="circle-cropper" :src="image" :stencil-size="getStencilSize"
        :stencil-props="getStencilProps" :canvas="getImageCanvas" @change="changeImage" image-restriction="stencil" />
      <div class="reset" @click="$refs.cropper.reset()">
        <el-icon class="el-icon-refresh-right"></el-icon>
      </div>
    </div>
    <div class="cropper-btn flex-jcfe">
      <el-button @click="$emit('changeCropper', false)">取消</el-button>
      <el-button @click="confirmEdit" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';
export default {
  props: {
    image: {
      type: String,
      require: true
    },
    canvas: {
      type: Object,
      default: () => {
        return { width: "", height: "" };
      }
    },
    aspectRatio: {
      //等于999 标注是等比
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      loading: true,
      imageParams: null
    };
  },
  watch: {
    image(val) {
      this.loading = true;
      this.getImageParams().then(res => {
        if (res) this.imageParams = res;
      });
    }
  },
  components: {
    CircleStencil,
    Cropper
  },
  created() {
    this.getImageParams().then(res => {
      if (res) this.imageParams = res;
    });
  },
  computed: {
    getImageCanvas() {
      const { width = "", height = "" } = this.canvas;
      return { width, height };
    },
    getStencilProps() {
      let aspectRatio = this.aspectRatio;
      if (this.aspectRatio == 999 && this.imageParams) {
        const { width = 1, height = 1 } = this.imageParams;
        aspectRatio = width / height;
      }
      return {
        handlers: {},
        resizable: false,
        aspectRatio
      };
    }
  },
  methods: {
    changeImage() {
      if (this.loading) {
        this.loading = false;
      }
    },
    getImageParams() {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = this.image;
        image.onload = e => {
          resolve(e.target);
        };
      });
    },
    getStencilSize({ boundaries }) {
      return { width: boundaries.width - 50, height: boundaries.height - 50 };
    },
    /**
     * 确认裁剪
     */
    confirmEdit() {
      const { canvas, image } = this.$refs.cropper.getResult();
      const imageType = image.src?.split(";")[0].split(":")[1] ?? "image/png";
      let that = this;
      canvas.toBlob(blob => {
        that.$emit("changeCropper", blob);
      }, imageType);
    }
  }
};
</script>

<style lang="less" scoped>
.cropper-cont {
  position: relative;

  &.mb10 {
    margin-bottom: 10px;
  }

  .circle-cropper {
    height: 285px;
    overflow: hidden;
  }

  .reset {
    position: absolute;
    right: 20px;
    top: 5px;
    font-size: 30px;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      color: #07a675;
    }

    &:active {
      transform: rotate(360deg);
    }
  }
}

.cropper-btn {
  &.flex-jcfe {
    display: flex;
    justify-content: end;
  }
}
</style>
