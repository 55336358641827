<template>
  <div class="">
    <el-upload
      class="avatar-uploader upload-item border-none"
      ref="upload"
      accept="image/*"
      :style="sizeStyle"
      :action="actionImage"
      :headers="headers"
      :data="params"
      :disabled="readOnly"
      :show-file-list="false"
      :on-error="function() { uploadError.apply(this, [...arguments]);}"
      :http-request="handleUpLoadImage"
      v-loading="loading"
      v-if="limit === 1"
    >
      <template v-if="list">
        <div
          v-for="(file, index) in [list]"
          :key="index"
          style="height:100%;width:100%"
        >
          <img class="upload-item-image" :src="file" />
          <span class="upload-item-actions">
            <span
              class="upload-item-actions-button"
              @click.stop="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!readOnly"
              class="upload-item-actions-button"
              @click.stop="handleRemove()"
            >
              <i class="el-icon-delete color-danger"></i>
            </span>
          </span>
        </div>
      </template>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>

    <el-alert
      class="upload-hint-text margin-top-10"
      type="info"
      v-if="showHintText && !readOnly"
    >
      <span class="" slot="title">
        图片只能上传jpg/png文件,需限制在{{ filesSize
        }}{{ filesSizeUnit }}以内；<slot name="hint"></slot>
      </span>
    </el-alert>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <el-dialog
      width="600px"
      v-if="isCropper"
      @close="fileImage = ''"
      :visible.sync="dialogVisibleCropper"
      append-to-body
    >
      <AdvancedCropper
        @changeCropper="changeCropper"
        :aspect-ratio="aspectRatio"
        :canvas="canvas"
        :image="fileImage"
      />
    </el-dialog>
  </div>
</template>

<script>
import { baseURL, URL, URL_MINOR } from "@/config";
import { uploadImageMixins } from "./uploadImage";
import AdvancedCropper from "@/components/public/advancedCropper";
export default {
  name: "uploadImagesOne",
  mixins: [uploadImageMixins],
  components: { AdvancedCropper },
  props: {
    limit: {
      // 最大允许上传个数
      type: Number,
      default: 1
    },
    multiple: {
      // 是否支持多选文件
      type: Boolean,
      default: false
    },
    file: {
      // 默认回显列表
      type: String
    },
    navigateInfo: {
      // 是否支持多选文件
      type: Object,
      default: () => {}
    },
    readOnly: {
      // 是否只读
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /* 文件上传 */
      list: "",
      fileImage: ""
    };
  },
  created() {
    this.setFileList(this.file);
  },
  methods: {
    /** 判断更新、冒泡 **/
    triggerFileList() {
      this.$emit("update:file", this.list);
    },
    /** 初始化设值 **/
    setFileList(list) {
      if (typeof list !== "string") {
        list = "";
      }
      this.list = list;
    },
    onStart(file) {
        console.log(1);
        this.$refs.upload.$children[0].uploadFiles([file]);
      // setTimeout(() => {
      // }, 500);
    },
    async uploadBeforeMulti(file, origin) {
      if (!this.uploadBefore(...arguments)) {
        return false;
      }
      return true;
    },
    handleRemove() {
      this.list = "";
      if (!this.navigateInfo?.id || !this.navigateInfo?.type) return;
      let data = {
        navigateId: this.navigateInfo.id,
        type: this.navigateInfo.type
      };
      this.axios
        .get(URL.countyCmsNavigate.deleteNavigate, { params: data })
        .then(res => {});
    },
    /**
     * 文件上传回调
     */
    uploadCallBack(data) {
      const { type, file, url } = data;
      if (type == "error") {
        this.uploadErrorMulti(file);
      } else {
        this.list = url;
      }
    }
  },
  watch: {
    file(newVal) {
      this.setFileList(newVal);
    },
    list(newVal) {
      this.triggerFileList();
    }
  }
};
</script>
<style lang="less">
.upload-item {
  .el-upload {
    width: 100%;
    height: 100%;
    line-height: 1;
    position: relative;
    box-sizing: border-box;

    .el-icon-plus {
      position: absolute;
      /*top: 50%;*/
      /*left: 50%;*/
      /*margin-left: -14px;*/
      /*margin-top: -14px;*/
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.avatar-uploader {
    .el-upload {
      .el-icon-plus {
        line-height: 1;
        /*width: 28px !important;*/
        /*height: 28px !important;*/
      }
    }
  }
}
</style>

<style scoped lang="less">
@import "../../../assets/css/data";

.upload-files-list {
  line-height: 1;

  .upload-item {
    float: left;
  }
}

.upload-item {
}

.upload-item {
  border-radius: 6px;
  overflow: hidden;
  border: @border-width-solid solid @border-color-one;
  box-sizing: border-box;
  position: relative;

  &:not(.avatar-uploader) {
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
  }

  &.border-none {
    border: none;
  }
}

.upload-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-item-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  &:hover {
    opacity: 1;

    .upload-item-actions-button {
      display: inline-block;
    }
  }
}

.upload-item-actions-button {
  display: none;
  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  &.disabled {
    color: @color-info;

    &:hover {
      transform: none;
    }
  }
}

.upload-item-actions-button-delete {
  @width: 26px;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 2;
  border-radius: 50%;
  background-color: #f2f2f2;
  color: #ff0000;
  /*background-color: @color-danger;
    color: white;*/
  /*font-size: 12px;
    width: @width;
    height: @width;
    line-height: @width*/
  text-align: center;

  &:hover {
    transform: none;
  }
}

.upload-hint-text {
  line-height: initial;
}
</style>
