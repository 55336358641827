import ImageCompressor from "@/utils/image.compressor";

var params = {
  quality: 0.5
};

/**
 * 压缩图片
 * @param {Number,File} quality file
 * @returns {File}
 */
export async function compressorImage({ quality = params.quality, file }) {
  const fileSize = file.size / 1024 / 1024;
  if (fileSize > 5) quality = 0.2;
  return new Promise((resolve, reject) => {
    new ImageCompressor({
      file: file,
      quality,
      convertSize: 1, // png转jpeg的阀值
      // 压缩成功回调
      success: function(result) {
        resolve(result);
      },
      error: msg => {
        console.error(msg);
        reject(new Error(msg));
      }
    });
  });
}

/**
 * *转JPG图片格式
 * @param {File} file
 * @returns {File}
 */
export function convertImage(file) {
  return new Promise((resolve, reject) => {
    let fileName = file.name ? file.name : `${Math.random() * 100 * 100}`;
    fileName = fileName.substring(0, fileName.indexOf("."));
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function(e) {
      let image = new Image();
      image.src = e.target.result;
      image.onload = function() {
        let canvas = document.createElement("canvas"),
          context = canvas.getContext("2d"),
          imageWidth = image.width,
          imageHeight = image.height,
          data = "";
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        context.drawImage(image, 0, 0, imageWidth, imageHeight);
        data = canvas.toDataURL("image/jpeg");
        var newFile = dataURLtoFile(data, fileName + ".jpeg");
        resolve(newFile);
      };
    };
  });
}
/**
 *  * base64转file
 * @param {String, String} dataURL filename
 * @returns {File}
 */
export function dataURLtoFile(dataURL, filename) {
  let arr = dataURL.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
